@font-face {
    font-family: 'Open Sans';
    src: url('fonts/OpenSans-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans Bold';
    src: url('fonts/OpenSans-Bold.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Bold';
    src: url('fonts/GothamBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('fonts/GothamBook.ttf');
    font-weight: 500;
    font-style: normal;
}