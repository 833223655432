/*********************************************************************
 * #### jQuery Awesome Sosmed Share Button / AyoShare.js v17 ####
 * Coded by Ican Bachors 2014.
 * https://github.com/bachors/jQuery-Awesome-Sosmed-Share-Button
 * Updates will be posted to this site.
 *********************************************************************/

.ayoshare {
	font-family: sans-serif;
	padding: 2px 2px;
    overflow: hidden;
}

.ayoshare .button {
    position: relative;
    display: block;
    float: left;
    height: 40px;
    margin: 2px;
    overflow: hidden;
    width: 98px
}

.ayoshare .button.small {
    width: 49px
}

.ayoshare .button a {
    cursor: pointer
}

.ayoshare .icon {
    display: block;
    float: left;
    position: relative;
    z-index: 3;
    height: 100%;
    vertical-align: top;
    width: 38px;
    text-align: center;
}

.ayoshare .icon i {
    color: #fff;
    line-height: 42px;
}

.ayoshare .mobile {
    display: block;
    float: left;
    position: relative;
    z-index: 3;
    height: 100%;
    vertical-align: top;
    width: 100%;
    text-align: center;
}

.ayoshare .mobile i {
    color: #fff;
    line-height: 42px;
}

.ayoshare .counter {
    z-index: 2;
    display: block;
    margin: 0;
    height: 100%;
    left: 38px;
    position: absolute;
    width: 60px
}

.ayoshare .counter p {
    font-weight: 400;
    border-left: 1px solid rgba(255,255,255,0.35);
    color: #fff;
    font-size: 16px;
    left: 0;
    margin: 0;
    position: absolute;
    text-align: center;
    top: 10px;
    width: 100%;
}

.ayoshare a {
	color:#fff;
	text-decoration:none;
}

.ayoshare .facebook .mobile,
.ayoshare .facebook .icon,
.ayoshare .facebook .counter {
	background-color: rgb(59, 89, 152);
}

.ayoshare .linkedin .mobile,
.ayoshare .linkedin .icon,
.ayoshare .linkedin .counter {
	background-color: #0074A1;
}

.ayoshare .stumbleupon .mobile,
.ayoshare .stumbleupon .icon,
.ayoshare .stumbleupon .counter {
	background-color: #EE4B13;
}

.ayoshare .bufferapp .mobile,
.ayoshare .bufferapp .icon,
.ayoshare .bufferapp .counter {
	background-color: #168eea;
}

.ayoshare .reddit .mobile,
.ayoshare .reddit .icon,
.ayoshare .reddit .counter {
	background-color: #6D91A6;
}

.ayoshare .pinterest .mobile,
.ayoshare .pinterest .icon,
.ayoshare .pinterest .counter {
	background-color: #C92228;
}

.ayoshare .vk .mobile,
.ayoshare .vk .icon,
.ayoshare .vk .counter {
	background-color: #45668e;
}

.ayoshare .pocket .mobile,
.ayoshare .pocket .icon,
.ayoshare .pocket .counter {
	background-color: #ef4056;
}

.ayoshare .google .mobile {
	background-color: #D24231;
}

.ayoshare .twitter .mobile {
	background-color: rgb(29, 161, 242);
}

.ayoshare .digg .mobile {
	background-color: #005be2;
}

.ayoshare .flipboard .mobile {
	background-color: #cc0000;
}

.ayoshare .email .mobile {
	background-color: rgb(132, 132, 132);
}

.ayoshare .whatsapp .mobile {
	background-color: #075e54;
}

.ayoshare .telegram .mobile {
	background-color: #0088cc;
}

.ayoshare .viber .mobile {
	background-color: #59267c;
}

.ayoshare .line .mobile {
    background-color: #00c300;
}

.ayoshare .bbm .mobile {
	background-color: #000000;
}

.ayoshare .sms .mobile {
	background-color: #05FF82;
}

/* add hover and active effects */
.ayoshare .button:hover .icon,
.ayoshare .button:hover .counter,
.ayoshare .button:hover .mobile,
.ayoshare .button:active .icon,
.ayoshare .button:active .counter,
.ayoshare .button:active .mobile {
	background-color: rgba(0, 0, 0, 0.3);
}

/* font awesome doesn't provide official Line Messenger , Bufferapp & BBM icon */
.ayoshare .fa-line {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAARuSURBVHja7Jh9TFV1GMe/55z7yuu5lzcvRFlJTWZwaS6Vpi4HlIBhaUJrOKeA8z/IVv3Ry8o/mi6nNXTVHBuKIBWkEYzBALmw4FIXx4ILiEMXyPu9cMFd79u5T38ECNbqgMfl1n2289/zPL/Pnrc952GICI+ysHjExQfoA/QB+gD/74AyMUob2jbA6rFiW9B2JGmTMO4ex5RrCjH+Maw+MH5XABeQFKWKiuXlvI4Bw866ZydGXaP9Nret+bp9oLJz1mTXyrXQKSJhtBlRM10NjUyDni090gAuhpvhMOmahIfcXJYu86OEoIT3FIxCeb8eL+ef5eX8VgA5m/hNF+IC476pt9S/O+YcszEPI4IMGDAAbjuG8XrYa/r0iLQmFavixdrqA+Pz9IHxeXWT9a+229qqBBLmPUpUgy64MOIYhT4ofsde3Z5rYuHul5Sw5B+TQ1NyJxwTcJJTnBER/esX1xqHDNPuSI/X4yIJJK/78IvrDbGi3hYVQQ2nRcET+Sc5hpNL0ZnvrD16WifXSRfBTwY+3bY0Au/3F1Dx8DkiInIKTsrvPULGmZ8ppzubLK4pIiLK7ztCJlsHZf/2BoVeVdPGtlhyCI5FH8dvnEgV87YoQNOM6cOlgDGGdZTbnUNERFaXlSIawql8tJxwBZTZuYeIiKKboqnwViGFNoRQ4dAXZJhuWpZm81zvMclSHBsYu3ZZypVqBMgV813GQKv2A8t4sTEyFpXjFfi4/wM8r1mPILkfeJUSl8e/RdnIebi97kUfT/k9GSlZF085p+b+boAsLRO7145ghkftC3X4/NYJtMy0wJ/zAwsWKaGp2Bm+C3L2XglbXdN2yQBPmk//smwQQ4dTg2eQ3JmIqsnL4KGDS/Ci13YTO7TJOBv/FazTd+ERGFjsdlyz/QqTrQNuuhfBL/sKuyRrkuBSTbB5xmxeqJ9mayNl9+yjl0xbqH6qlgzTTTRov0FlY+fJS14iIro4WkwjjttUOHSKMrpeoVzzfnIKTiIimnHZbGGl4WGSNQkKgcyrWVkkkRR0vH0UZ0S+LUqpBMBZ4NxAUdGDwv00XF2DrwGUQLpBzXJqwB/IqT94sGiwuHi1A7rNYjSm16alQgGwMrWE+yARWKUa8GNxqObAgb0tWW/Nee64VgJXPVZbl/j95s0AwPqpAZEHA/ELq5fAKpRgNHJUdJWX8hdDAve3HzpcO1LXavfYvf9keqzns+PpFTtfhgxgA9SAV/w1gxFz+uDK5tNB8x/LwOt2AnNegAMgAxehiX7alNpeFaWOfGbBbs5zx5HemJFu6G1sgJYDK1P86WB+hApZdx/Syk8EVqYAwysBJaALeVzY+lji9Tlh1rygcmnoux/4kpAgw0BjAxuuBMspVhS5VW3Uf5mfghPPrUnAm+v2wUMCdKpIvcVpQVrz7u3GvlYDAhiwIQspXd2RSvZAexMrg4cEVN68gjH7ONawuuJLv5e3G/taDfBnwKpVq4raimvQ99vpA/QB+gB9gP+d/DEAL5Ee2yGyv1IAAAAASUVORK5CYII="); /* Line Logo */
    background-size: cover;
    width: 32px;
    height: 32px;
    margin: 3px 0 0 0;
    padding: unset;
}

.ayoshare .fa-bufferapp {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAHiSURBVHja7NhPiE5RGMfx5yWzUTQbFhYkkw2pKSVKlNhgIxaS1GiKsGJjg4UUCwtTlI2sJH82U0KNshEJKYVJIgtqmjdiUkwfm6Nu1zXjfd/7vkbur07de89zzvl2zv0959xbQ0xlTYsprgqwAqwAK8B/CHBbRNyLiOGIOBoRs0vpFa2WnXjsV33GCcxppf9WwHbjpcn1DaexoBOANezHW83pHBa1C/AUxpWjB+j7k3EbMcmniBgtyVAjETHWLpPswLMmZu1rM6ZpxSRb0lJNplEcwaxOuXhJ7n4z7hSAvcchzMjELm0UtBGwPXiSBn+OXbn6dbiJRziQq1uOa6ntGM5iQ9mA5wtm6RX2TtBmLW4VtHs9Sbuml3gujuNLbsB3OIiuFLcRQwVgV7CqEyaZiWOo5wA+4k0B2GX0dsok2Ze+Kxnhw28cfAGLM/HT2wm4Jg1YxyBW5Or3YRgjOIOFmbrulAPr6WDRV5ANWga8XjBDg1hdsF//vJ6HkylJZzWOw+1Y4pUJKq8hrM/EzcdAQdwL9HfCJL24VADwELcLnj/F9k6fBwPLcHGCbe4+tv6tA2u29KRE/j2B3cWmMvqulfjzqCci+iOiOyKuRsSNMjotE7D67KwAK8AKsAL8HwF/DAC3lzjL9tbjTwAAAABJRU5ErkJggg=="); /* Bufferapp Logo */
    background-size: cover;
    width: 32px;
    height: 32px;
    margin: 3px 0 0 0;
    padding: unset;
}

.ayoshare .fa-bbm {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAANJSURBVHja7JY9iF1FFMf/Z87M3K/dfb5lu6BCwEoUGwsLKxtFAkEELSwtVoSATVq/GgsLEcEuEFKkMYEQxaCIWAQMpFQERUQQlvWDt+t9b9+983GOxXvPWLi7vIchIHuaGbjM/O6c8/+fGVJV3IswuEdxAj4B37Wwh3347dffYdhUG4P1jw1xDaAFgQAACwfSfLzjSAWwliQGmeoZp7a1A7scuG7qF4vCvxZivNV1k3POFRDJMx4ZAApVBYhAIKgKiAxyiigr/65vypshxPcscOHf9qcjGshnOcupg4ODh0GEGBNyCmBmGMNQFeScwcxgtjDWQXIGQZFTwtrG+m1m3iXg2WVrzERIbD1UFTlFDIebP2xubiqzecL74vmtrS1tmuZGzgnWGMTQAwB8UYCATEdk9CiwqgJ96CGisM4h5/RpjOm6qv4hIvsp5c9jipfZ2geLwl+qquqVlDJUFUREq6qaAMCQmdUPOA1AY4pXc5bvmfnRlNLXXRcuWuYXiPBSURSvqspCbbSSqu/AFSoKX/gni6I4JyK/9F1/ua6rdwDYkMIXIvL+ZHJwfTqdfue9Azu3up0WIapgZuScr7Tt+KOU0gREW+PJ5JmU8u0saW/zvuEBEVUi+WxK+Zo1Bsdk+jjwTPI6k/9YVJGyYL2pt8uqfDtn+aZt20dSSh+IyLjvw1dVVcJai7lbaBUwATAqAucdoIKUMtgAfQg3RfFGzukGiBBCOB9jBDOjKAos5kSkS4ur7/tviWjLWfu4+cd/V2UJEL7s+umbCr3lLAMAnHOoqhIhREyn3WOqeqrv+x8PPdVhDWRnZwdlWT49HA4viMiVth2ft9Y+1TT1NoCdxbrZqAAIRCSqClV9ruv6l1OSa4PB2nLg3d1dqALO+wcGG+ufxJg+dN6/NRrtv26I1qw1NPfr32pQFfbeUdd1l8jYn70vsNaUy9XYuVnHYmPuN8ZUzPZsP20fWq8wGv3ZYTLJMPMaLOA5Z9T1DMTGrHYtqipEBFVdbUPR7O3tn/HejMqmxsZGBSKCtQxred6vZ3Nj+LjecfSJs2QQEUTkp1G7f1qB3hABkFleie7OfcyGQER1COGqqEyNIfyXL2E6eVefgP934L8GAFPbrYEFvuxUAAAAAElFTkSuQmCC"); /* BBM Logo */
    background-size: cover;
    width: 32px;
    height: 32px;
    margin: 3px 0 0 0;
    padding: unset;
}

.ayoshare .fa-flipboard {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAABCCAYAAADjVADoAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAADWSURBVHja7NsxDoIwFAbgYhw4goOcgEM4cAjvy+AtYJGhoyOJQ12YHMDEJlb9/pWmTb7kvbw0pUopBQlhhwAECBAgQIAAAQIECBAgQIAAAQIECBAgyss+52bzPF+31tyGYcxx1qFtT8VCLDm++f2V9EpDjwABAgSIH5kjMqX/C4h7jFtLYtN1Z6WhR4AAAQIECAPVU6YQwqVEiCrnO8vlhmrt4mWq67pRGnoECBAgQIAwUK0MTV+Xyo8rSgMECBAgQIAAAQIECBAgQIAA8Zk8AAAA//8DAIUmH3yIYUyXAAAAAElFTkSuQmCC"); /* Flipboard Logo */
    background-size: cover;
    width: 32px;
    height: 32px;
    margin: 3px 0 0 0;
    padding: unset;
}
