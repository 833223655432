
//load libs
@import "../libs/normalize.less";
@import "../libs/bootstrap3.less";
@import "../libs/font-awesome.less";
@import "../libs/sweetalert.less";
@import "../libs/slick.less";
@import "../libs/slick-theme.less";
@import "../libs/lightbox.less";
@import "../libs/ayoshare.less";
@import "../../fonts/fonts.less";


//Custom
@import "../core/variables.less";

*,
*:hover,
*:focus,
*:visited,
*:active {
	outline: 0 !important;
}

body {
	font-size: 16px;
	font-family: 'Open Sans';
}

.container-fluid {
	max-width: 1900px;
	margin: 0 auto;
}

.reset-list {
	margin: 0;
	padding: 0;
}

.inline-block {
	display: inline-block;
	*display: inline;
	zoom: 1;
}

.transition(@args:200ms) {
  -webkit-transition: @args;
  -moz-transition: @args;
  -o-transition: @args;
  -ms-transition: @args;
  transition: @args;
}

.text-center {
	text-align: center;
}

img {
	max-width: 100%;
	height: auto;
}

.pagination {
	.reset-list;
	margin: 1rem 0;
	text-align: center;

	li {
		.inline-block;
		list-style: none;
		margin: 0 .2rem;

		span,
		a {
			display: block;			
			padding: .5rem 1rem;
			border-radius: 3px;
		}

		span {
			background: @secondary-color;
			color: @terceary-color;
		}

		a {
			border: 1px solid @secondary-color;
			color: @secondary-color;

			&:hover {
				background: @secondary-color;
				color: @terceary-color;
			}
		}

		&.disabled span {
			background: fade(@secondary-color, 65)
		}
	}
}

#recaptcha2,
#recaptcha1 {
	overflow: hidden;
}

.alert {
	text-align: center;
    padding: 1rem;
    margin-top: 2rem;
    font-size: 1.4rem;
    font-weight: 500;
    font-family: 'HelveticaNeue LT 45';

    &.alert-error {    	
	    background: #ef7d7d;
	    color: #fff;
    }

    &.alert-warning {    	
	    background: #ecb258;
	    color: #fff;
    }

    &.alert-success {    	
	    background: #9ecc83;
	    color: #fff;
    }
}

//Flex Upload
.FlexUpload-drop {
	position: relative;
	margin: 1rem 0;
	border: 1.5px dashed #c7c7c7;
    background-color: #eee;					    
	font-size: 1.25rem;
	text-align: center;					   
}

.FlexUpload-drop.is-dragover {
	outline-offset: -20px;
	outline-color: #c8dadf;
	background-color: #fff;
}

.FlexUpload-drop input[type='file'] {
	display: block;
	width: 100%;
	height: 100%;						
	padding: 1rem 2rem;
	opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.FlexUpload-drop .box-dragndrop {
	color: #333;
}

.FlexUpload-drop input[type='file'] + label {
	display: block;
	margin-bottom: 2rem;
}

.FlexUpload-drop .box-uploading,
.FlexUpload-drop .box-error {
  display: none;
  margin-bottom: 2rem;
}

.FlexUpload-drop .box-icon {
    width: 100%;
    height: 40px;
    fill: #383333;
    display: block;
    margin: 2rem 0;
}

.FlexUpload-Panel {
	margin: 2rem 0;
	padding: 1rem 2rem;
	max-height: 400px;
	overflow-y: auto;
	background: #fff;
	border: 1.5px solid #eee;
}

.FlexUpload-Panel ul {
	margin: 0;
	padding: 0;
}

.FlexUpload-Panel .options {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center;
}

.FlexUpload-Panel article:hover .options {
	display: block;
}

.FlexUpload-Panel .options li {
	display: inline-block;
	*display: inline;
	zoom: 1;
	margin: .5rem;
}

.FlexUpload-Panel .options li a {
	display: block;
	padding: .5rem 1rem;
	background: #3f49a5;
	color: #fff;
	font-size: 1.4rem;
	border-radius: 5px;
	cursor: pointer;
}

.FlexUpload-Panel .options li a.btn-delete {
	background: #d81e1e;
}

.FlexUpload-Panel img {
	max-width: 100% !important;
	max-height: 320px;
}

.FlexUpload-Panel .text-center {
	text-align: center;
}

.FlexUpload-Panel .vertical-align {
	position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.FlexUpload-Panel::-webkit-scrollbar {
    width: .5em;
    background: #fff;
}
 
.FlexUpload-Panel::-webkit-scrollbar-track {
	background: rgba(240, 240, 240, 0.5);
}
 
.FlexUpload-Panel::-webkit-scrollbar-thumb {
  background-color: #383333;
}

.FlexUpload-Panel article {
	position: relative;
	margin: 1.5rem 0; 
}

.badge {
	display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #777;
    border-radius: 10px;
}

h1{
	color: @secondary-color;
	font-family: 'Open Sans Bold';
	font-size: 5rem;
	text-transform: uppercase;

	@media(max-width: 480px){
		font-size: 4rem;
	}
}

p{
	font-family: 'Open Sans';
	text-align: justify;
}

.btn-default{
	background-color: @primary-color;
	border: none;
	color: @terceary-color;

	&:hover{
		background-color: @primary-color;
		color: @terceary-color;
	}
}

.btn-purple{
	background-color: @secondary-color;
	border: none;
	color: @terceary-color;

	&:hover{
		background-color: @primary-color;
		color: @terceary-color;
	}
}

.btn-see-more{
	background-color: @terceary-color; 
	border: 1px solid @terceary-color;
	color: @primary-color;
	border-radius: 0.5rem;
	padding: 0.5rem 3rem;

	&:hover{
		background-color: @terceary-color;
		border: 1px solid @terceary-color;
		color: @primary-color;
	} 
}

.info-box{
	background: #ccc;
	padding: 1rem;
	float: left;
	width: 100%;
	color: #000;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'Open Sans Bold';

	&.yellow{
		background: #fff802;
	}

	&.purple{
		background: @secondary-color;
		color: @terceary-color;
	}

	&.pink{
		background: @primary-color;
		color: @terceary-color;
	}
}

.divPopUp{
    display: block;
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    height: 100%;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 10000;
}
.divPopUpBody{
    width: 72rem;
    height: 40rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 18rem;
    margin-bottom: auto;
    display:flex;
}
.videoPopUp{
    height: 100%;
    width: 90%;
    padding: 1rem;
}
.buttonClosePopUp{
    color: white;
    font-size: 3rem;
    font-weight: 700;
    border-radius: 50%;
    background-color: #00b8d5;
    width: 4rem;
    text-align: center;
    height: 4rem;
}

@media(max-width: 620px){
  .divPopUpBody {
      width: 57rem;
      height: 30rem;
      margin-left: auto;
      margin-right: auto;
      margin-top: 24rem;
      margin-bottom: auto;
      display: flex;
  }
  .buttonClosePopUp {
      color: white;
      font-size: 2.2rem;
      font-weight: 700;
      border-radius: 50%;
      background-color: #dc4747;
      width: 3.3rem;
      text-align: center;
      height: 3.3rem;
  }
}
@media(max-width: 534px){
  .divPopUpBody {
      width: 43rem;
      height: 23rem;
      margin-left: auto;
      margin-right: auto;
      margin-top: 24rem;
      margin-bottom: auto;
      display: flex;
  }
}
@media(max-width: 425px){
  .divPopUpBody {
      width: 30rem;
      height: 17rem;
      margin-left: auto;
      margin-right: auto;
      margin-top: 24rem;
      margin-bottom: auto;
      display: flex;
  }
}
@media(max-width: 320px){
  .divPopUpBody {
      width: 24rem;
      height: 14rem;
      margin-left: auto;
      margin-right: auto;
      margin-top: 24rem;
      margin-bottom: auto;
      display: flex;
  }
}